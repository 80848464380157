<template>
  <el-dialog v-if="dialogVisible"
             :visible.sync="dialogVisible"
             width="40%"
             class="nsi-technical-reason"
             :close-on-click-modal="false">
    <div slot="title">
      Объединение объектов
    </div> 
    <div class="main-item">
      {{mainItem.name === ''? '(без названия)':mainItem.name}}
    </div>
    <el-divider content-position="left">Присоединяемые объекты:</el-divider>
    <div class="secondary-items">
      <el-tag v-for="item in secondaryItems"
              :key="item.id"
              closable disable-transitions effect="plain"
              @close="handleItemRemove(item)" @click="handleItemClick(item)">
        <span>{{item.name === ''? '(без названия)':item.name}}</span>
      </el-tag>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="handleClose" size="small">Отменить</el-button>
      <el-button @click="handleMerge" size="small" type="success">Объединить</el-button>
    </div>
  </el-dialog>
</template>

<script>  
  export default {
    name: 'ProductAssetModelMergeModal',
    props: ['value', 'items'],
    computed: {
      dialogVisible: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit('input', val);
        },
      },
      mainItem() {
        if ((!this.selectedMainItem) || (!this.items.some((x) => x.id === this.selectedMainItem.id))) {
          const isAipItems = this.items.filter((x) => x.isAip);
          if (isAipItems.length > 0) {
            return isAipItems[0];
          } else {
            return this.items[0];
          }
        } else {
          return this.selectedMainItem;
        }
      },
      secondaryItems() { 
        return this.items.filter((x) => x.id !== this.mainItem.id);
      }
    },

    data() {
      return {
        loading: false,
        selectedMainItem: null,
      };
    },
    methods: {
      async handleMerge() {
        this.$emit('on-merge', {
          mainItem: this.mainItem, secondaryItems: this.secondaryItems
        });
      },
      handleItemClick(item) {
        this.selectedMainItem = item;
      },
      handleItemRemove(item) {
        this.$emit('on-merge-item-remove', item);
      },
      handleClose() {
        this.dialogVisible = false;
      },
    },
  };
</script>

<style scoped lang="scss">
  .main-item{
    font-weight:bold;
  }
  .secondary-items {
    border: 1px solid #e9e9eb;
  }

  .el-tag { 
    border: none;
    display: block;
  }

    .el-tag > * {
      cursor: pointer;
    }

    .el-tag::v-deep .el-tag__close {
      color: #3078bf !important;
    }

      .el-tag::v-deep .el-tag__close:hover {
        background-color: transparent !important;
        border: solid 1px #3078bf;
      }
</style>
