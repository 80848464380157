<template>
  <el-collapse v-model="filter" @change="handleChange">
    <el-collapse-item title="Фильтр" name="p1">
      <template v-slot:title>
        <span>
          <span>Фильтр</span>
          <el-button  class="ml-5"
              plain
              size="mini"
              type="default"
               @click.stop="clearFilters" 
               style="font-family: Montserrat-Medium !important;">
            Очистить фильтры
          </el-button>
        </span>
      </template>
      <el-row class="mt-2 filter" :gutter="10">
        <el-col :span="12" class="ml-2 filter--container">Наименование:</el-col>
        <el-col :span="12" class="ml-0 filter--container">Производитель:</el-col>
      </el-row>
      <el-row class="m-0 filter">
        <el-col :span="12" class="ml-2 filter--container">
          <remote-search-input
            size="small"
            v-model="filterModel.nameFilter"
            searchUrl="/api/productAssetModels/SearchByName"
          />
        </el-col>

        <el-col :span="12" class="ml-0 filter--container">
            <el-select multiple filterable clearable size="small"
                       ref="producerOrganizationsSelector"
                       :collapse-tags="filterModel.producerName.length > 5"
                       v-model="filterModel.producerName">
              <div class="pl-3">
                <el-button type="text" size="small"
                           @click="filterModel.producerName = getSelectedValuesByName(producerOrganizations, $refs.producerOrganizationsSelector.query)">
                  Выбрать всё
                </el-button>
              </div>
              <el-option
                v-for="item in producerOrganizations"
                :key="item.id"
                :label="item.name"
                :value="item.name">
              </el-option>
            </el-select>
        </el-col>
      </el-row>

      <el-row class="mt-2 filter" :gutter="10">
        <el-col :span="12" class="ml-2 filter--container">Исполнение:</el-col>
        <el-col :span="6" class="ml-0 filter--container">Среда передачи информации:</el-col>
        <el-col :span="6" class="ml-0">Отображать объекты локальной НСИ:</el-col>
      </el-row>

      <el-row class="m-0 filter">
        <el-col :span="12" class="ml-2 filter--container">
            <el-select multiple filterable clearable size="small"
                       ref="executionsSelector"
                       :collapse-tags="filterModel.executionName.length > 5"
                       v-model="filterModel.executionName">
              <div class="pl-3">
                <el-button type="text" size="small"
                           @click="filterModel.executionName = getSelectedValuesByName(executions, $refs.executionsSelector.query)">
                  Выбрать всё
                </el-button>
              </div>
              <el-option
                v-for="item in executions"
                :key="item.id"
                :label="item.name"
                :value="item.name">
              </el-option>
            </el-select>
        </el-col>

        <el-col :span="6" class="filter--container ml-0">
          <el-select  multiple clearable v-model="filterModel.transmissionMediumIdFilter" :collapse-tags="filterModel.transmissionMediumIdFilter.length > 3" size="small" filterable popper-class="search-select">
          <el-option v-for="it in transmissionMediums"
                     :value="it.id"
                     :key="it.id"
                     :label="it.code"
          ></el-option>
        </el-select>
        </el-col>
        <el-col :span="6" class="ml-0">
          <el-switch style="width: 100%;"
              v-model="filterModel.isLocalNsiFilter"
              active-color="#13ce66"
              inactive-color="#ff4949"
            />
        </el-col>
      </el-row>
      <!-- <el-row class="ml-1 mt-1 filter">
        <el-col :span="8" class="ml-2 filter--container">
          <el-button @click="clearFilters" type="text" style="font-family: Montserrat-Medium !important;">
            Очистить фильтры
          </el-button>
        </el-col>
      </el-row> -->
    </el-collapse-item>
  </el-collapse>
</template>

<script>
import { mapGetters } from 'vuex';
import RemoteSearchInput from '@/components/Ui/RemoteSearchInput';
import UserSettingsService from '@/service/userSettingsService';
import transmissionMediumsApi from '@/api/nsi/transmissionMediums';
import filterHelper from '@/mixins/filterHelper';

export default {
  name: 'ProductAssetModelsFilter',
  props: {
    filterModel: { type: Object, default: () => {} },
  },
    mixins: [filterHelper],
  components: { RemoteSearchInput },
  data() {
    return {
      filter: [''],
      transmissionMediums: [],
    };
  },
  async created() {
    if (UserSettingsService.has('productAssetModelsFilter_filter_open', this.user.id)) {
      this.filter = ['p1'];
    }
      const tr = await transmissionMediumsApi.getTransmissionMediumsWithPagination(1, 1000000, null);
      this.transmissionMediums = tr.data.items;
  },
  computed: {
    ...mapGetters('dictionaries', ['producerOrganizations', 'executions']),
    ...mapGetters('identity', ['user'])
  },
  methods: {
    handleChange(val) {
      if (val.find((el) => el === 'p1')) {
        UserSettingsService.set('productAssetModelsFilter_filter_open', true, this.user.id);
      } else {
        UserSettingsService.delete('productAssetModelsFilter_filter_open', this.user.id);
      }
      const objectLength = Object.keys(val);
      if (objectLength.length > 0) {
        this.$emit('active-change', true);
       } else {
        this.$emit('active-change', false);
      }
    },
    clearFilters() {
      this.$emit('clear-filters');
    },
  }
};
</script>

<style scoped>

</style>
