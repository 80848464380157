import axios from 'axios';

export default {
  getProductAssetModelsWithPagination(pageNumber, pageSize, nameFilter, producerName, executionName, isLocalNsiFilter, transmissionMediumIdFilter, sortField, sortDesc) {
    const params = {
      pageNumber,
      pageSize,
      nameFilter,
      producerName: producerName.length > 0 ? producerName.join('!;!') : '',
      executionName: executionName.length > 0 ? executionName.join('!;!') : '',        
      isLocalNsiFilter,
      transmissionMediumIdFilter,
      sortField,
      sortDesc
    };
    return axios.post('/api/ProductAssetModels/GetProductAssetModelsWithPagination', params);
  },
  getProductAssetModel(id) {
    return axios.get('/api/ProductAssetModels/GetProductAssetModel', {
      params: {
        id
      }
    });
  },
  addProductAssetModel: (model) => axios.post('/api/ProductAssetModels', model),
  updateProductAssetModel: (model) => axios.put(`/api/ProductAssetModels/${model.id}`, model),
  deleteProductAssetModel: (id) => axios.delete(`/api/ProductAssetModels/${id}`),
  mergeProductAssetModels(mainId, secondaryIds) {
    return axios.post('/api/ProductAssetModels/Merge', { mainId, secondaryIds });
  }
};

